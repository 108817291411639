import moment from 'moment';

const filters = {
    price: (val, currency = true) => {
        const locale = 'en-US';
        const opts = {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        };
        return isNaN(val) ? '0' : val.toLocaleString(locale, currency ? opts : {});
    },
    peso: (val, rate) => {
        let amount = val * (rate ? rate : 1);
        const locale = 'en-PH';
        const opts = {
            style: 'currency',
            currency: 'PHP',
            maximumFractionDigits: 2,
        };
        return isNaN(amount) ? '0' : amount.toLocaleString(locale, opts);
    },
    percent: (val) => {
        return typeof val === 'number' ? val.toFixed(2) + '%' : val;
    },
    date: (val, format) => {
        let utc = moment.utc(val);
        return utc.local().format(format);
    },
    decimal: (val, decimal) => {
        return +parseFloat(val).toFixed(decimal);
    },
    shorten: (text) => {
        return text ? text.slice(0, 4) + '..' + text.slice(-4) : text;
    },
};

export default filters;