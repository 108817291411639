import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { CancelToken, isCancel } from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import DataTable from '../plugins/DataTable';
import OfferActions from './OfferActions';
import api from '../../utils/api';
import array from '../../utils/array';
import dom from '../../utils/dom';
import filters from '../../utils/filters';
import token from '../../utils/token';
import constant from '../../utils/constant';

const OffersTable = forwardRef(({ mine }, ref) => {
    const mounted = useRef(false);

    const wallet = useWallet();

    const [requestGet, setRequestGet] = useState(null);

    const [filter, setFilter] = useState({
        mine: mine,
        offererAccount: wallet.publicKey ? wallet.publicKey.toString() : '',
        status: 'open',
        network: constant.network,
    });
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState('');
    const [sort, setSort] = useState('createdAt');
    const [order, setOrder] = useState('desc');
    const [next, setNext] = useState('');
    const [last, setLast] = useState('');

    const [headers, setHeaders] = useState([]);
    const [tokens, setTokens] = useState(new Map());

    // const [preSearch, setPreSearch] = useState('');
    const [preLast, setPreLast] = useState('');

    const [items, setItems] = useState([]);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const toastId = 'offers';

    useImperativeHandle(ref, () => ({
        refresh() {
            getData(true);
        },
        update(item) {
            setItems(array.modify(items, 'id', item));
        },
        remove(id) {
            setItems(array.remove(items, 'id', id));
        },
    }));

    const handleSort = (key) => {
        setItems([]);
        setOrder(order === 'asc' ? sort === key ? 'desc' : 'asc' : 'asc');
        setSort(key);
    };

    const getData = (refresh = false) => {
        setLoading(true);
        setError('');
        if (refresh) {
            setItems([]);
        }
        const req = CancelToken.source();
        setRequestGet(req);
        const params = {
            params: {
                'filter[mine]':  filter.mine,
                'filter[offererAccount]':  filter.offererAccount ? filter.offererAccount : undefined,
                'filter[status]':  filter.status ? filter.status : undefined,
                'filter[network]':  filter.network ? filter.network : undefined,
                search: search ? search : undefined,
                limit: limit ? limit : undefined,
                sort: sort ? sort : undefined,
                order: order ? order : undefined,
                last: refresh ? undefined : last ? last : undefined,
            },
            cancelToken: req.token,
        };
        api.pub.offers.get(params)
        .then(res => {
            if (mounted.current) {
                const data = res.data;
                if (refresh) {
                    setItems(data.data)
                } else {
                    setItems(prev => [...prev, ...data.data]);
                }
                setNext(data.next);
                setPreLast(data.last);
            }
        })
        .catch(err => {
            if (mounted.current) {
                if (isCancel(err)) {
                    setError('Cancelled');
                    return;
                }
                if (err.response) {
                    const data = err.response.data;
                    setError(data.message);
                } else {
                    setError(err.message);
                }
            }
        })
        .then(() => {
            if (mounted.current) {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        console.log('new');
        setLast('');
        getData(true);
    }, [filter, search, limit, sort, order]);

    useEffect(() => {
        if (last) {
            console.log('last');
            getData();
        }
    }, [last]);

    useEffect(() => {
        setFilter(prev => { return {...prev, offererAccount: wallet.publicKey ? wallet.publicKey.toString() : ''} });
    }, [wallet.publicKey]);

    useEffect(() => {
        const getTokens = async () => {
            setTokens(await token.list());
        }
        getTokens();
    }, []);

    useEffect(() => {
        const getHeaders = () => {
            const offeredCallback = (item) => {
                const t = tokens.get(item.offeredToken);
                const symbol = t ? t.symbol : filters.shorten(item.offeredToken);
                return (
                    <div>
                        {item.offeredAmount} {symbol}
                    </div>
                )
            }

            const wantedCallback = (item) => {
                const t = tokens.get(item.wantedToken);
                const symbol = t ? t.symbol : filters.shorten(item.wantedToken);
                return (
                    <div>
                        {item.wantedAmount} {symbol}
                    </div>
                )
            }
            
            const array = [
                { name: 'offeredToken', label: 'Offered', sortable: true, callback: offeredCallback },
                { name: 'wantedToken', label: 'Wanted', sortable: true, callback: wantedCallback },
                { name: 'status', label: 'Status', sortable: true },
                { name: 'createdAt', label: 'Created Date', sortable: true, callback: (item) => filters.date(item.createdAt, 'YYYY/MM/DD') },
                { name: 'id', label: 'Action', sortable: true, callback: (item) => <OfferActions item={item} mine={mine} onUpdate={() => getData(true)} /> },
            ];

            if (!mine) {
                array.splice(0, 0, { name: 'offererAccount', label: 'Offerer', sortable: true });
            }

            setHeaders(array);
        }
        getHeaders();
    }, [tokens]);

    // useEffect(() => {
    //     const debounce = setTimeout(() => {
    //         setSearch(preSearch);
    //     }, 500);
    //     return () => clearTimeout(debounce);
    // }, [preSearch]);

    useEffect(() => {
        const container = document.getElementById('container');
        const loadMore = () => {
            const loadmore = document.getElementById('loadmore');
            if (dom.viewport(loadmore)) {
                const preLastAttr = loadmore.getAttribute('prelast');
                setLast(preLastAttr);
            }
        }
        container.addEventListener('scroll', loadMore);
        return () => container.removeEventListener('scroll', loadMore);
    }, []);

    useEffect(() => {
        return () => {
            if (requestGet) requestGet.cancel();
        }
    }, [requestGet]);

    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    return (
        wallet.publicKey
        ?
        <div>
            <DataTable headers={headers} items={items} loading={loading} error={error} sort={sort} order={order} action={false} handleSort={handleSort} onRetry={() => getData()} />
            {
                next && !loading && !error
                ?
                <button id='loadmore' type='button' className='btn btn-link w-100' prelast={preLast} onClick={() => getData()}>Load more</button>
                :
                null
            }
        </div>
        :
        null
    );
});

export default OffersTable;