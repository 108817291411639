
import React from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import ChatButton from '../plugins/ChatButton';
import imgLogo from '../../img/logo.svg';
// import NotificationsButton from '../plugins/NotificationsButton';

const Navbar = () => {
	return (
		<nav className='navbar navbar-expand navbar-dark shadow-sm'>
			<div className='container-fluid'>
				<a href='/' className='navbar-brand'>
                    <img src={imgLogo} alt='' className='logo-shadow me-2' width='45' height='45' />
                </a>
				<div id='navbar'>
					<ul className='navbar-nav ms-auto mb-0'>
						<li className='nav-item nav-link'>
							<ChatButton />
						</li>
						{/* <li className='nav-item nav-link'>
							<NotificationsButton />
						</li> */}
						<li className='nav-item nav-link'>
							<WalletMultiButton />
						</li>
					</ul>
				</div>
			</div>
		</nav>
	)
}

export default Navbar;