function Input({ type, name, label, icon, value, onInput, onWheel, errors, autoFocus, readOnly, minimal = false }) {
    const getErrors = (name) => {
        if (Array.isArray(errors)) {
            return errors.filter(i => i.param === name);
        }
        return [];
    }

    return (
        <div className='d-flex align-items-center w-100'>
            {
                icon
                ?
                <div className={`me-3 ${minimal ? 'mt-3' : ''}`}>
                    <i className={`icon icon-${icon} icon-lg`}></i>
                </div>
                :
                null
            }
            <div className='form-floating flex-grow-1'>
                <input type={type} className={`form-control ${getErrors(name).length ? 'is-invalid' : ''} ${minimal ? 'minimal' : ''}`} step={type === 'number' ? 'any' : ''} min={type === 'number' ? '0' : ''} id={name} placeholder={label} value={value} onInput={onInput} onWheel={onWheel} readOnly={readOnly} autoFocus={autoFocus} />
                <label htmlFor={name}>{label}</label>
                {
                    getErrors(name).length
                    ?
                    <div className='invalid-feedback'>
                        {getErrors(name).map((i, idx) =>
                            <span key={idx}>{i.msg}&nbsp;</span>
                        )}
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );
}

export default Input;