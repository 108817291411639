function Submit({ label, loading, className }) {
    return (
        <button type='submit' className={`btn btn-primary w-100 ${className ? className : ''} ${loading ? 'disabled' : ''}`}>
            {
                loading
                ?
                <span className='text-light'>
                    <span className='spinner-border spinner-border-sm' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </span>
                </span>
                :
                <span>{label}</span>
            }
        </button>
    );
}

export default Submit;