import { TOKEN_PROGRAM_ID, getOrCreateAssociatedTokenAccount } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { BN, web3 } from '@project-serum/anchor';
import constant from '../utils/constant';
import token from '../utils/token';

const { Keypair, SystemProgram, SYSVAR_RENT_PUBKEY } = web3;

const make = async (wallet, _offeredToken, _wantedToken, _offeredAmount, _wantedAmount) => {
	try {
		const program = constant.program(wallet);
		const connection = program.provider.connection;
		
		const offeredToken = new PublicKey(_offeredToken)
		const wantedToken = new PublicKey(_wantedToken);

		const offeredTokenDecimals = await token.decimals(_offeredToken);
		const wantedTokenDecimals = await token.decimals(_wantedToken);

		const offeredAmount = new BN(_offeredAmount * (10 ** offeredTokenDecimals));
		const wantedAmount = new BN(_wantedAmount * (10 ** wantedTokenDecimals));

		const offererOfferedAssociatedTokenAccount = await getOrCreateAssociatedTokenAccount(connection, wallet, offeredToken, wallet.publicKey);
		const offererOfferedTokenAccount = offererOfferedAssociatedTokenAccount.address;

		const offererWantedAssociatedTokenAccount = await getOrCreateAssociatedTokenAccount(connection, wallet, wantedToken, wallet.publicKey);
		const offererWantedTokenAccount = offererWantedAssociatedTokenAccount.address;

		const offer = Keypair.generate();
		const [escrowAccount, escrowAccountBump] = await PublicKey.findProgramAddress(
			[offer.publicKey.toBuffer()],
			program.programId
		);

		const tx = await program.rpc.makeOffer(
			escrowAccountBump,
			offeredAmount,
			wantedAmount,
			{
				accounts: {
					offer: offer.publicKey,
					offererAccount: program.provider.wallet.publicKey,
					offererOfferedTokenAccount,
					escrowAccount,
					offeredToken,
					wantedToken,
					tokenProgram: TOKEN_PROGRAM_ID,
					systemProgram: SystemProgram.programId,
					rent: SYSVAR_RENT_PUBKEY,
				},
				signers: [offer]
			}
		);

		return {
			tx,
			offer: offer.publicKey.toString(),
			offererAccount: program.provider.wallet.publicKey.toString(),
			escrowAccount: escrowAccount.toString(),
			offererOfferedTokenAccount: offererOfferedTokenAccount.toString(),
			offeredToken: offeredToken.toString(),
			wantedToken: wantedToken.toString(),
			offererWantedTokenAccount: offererWantedTokenAccount.toString(),
			offeredAmount: offeredAmount / 10 ** offeredTokenDecimals,
			wantedAmount: wantedAmount / 10 ** wantedTokenDecimals,
			network: constant.network,
		}
	} catch (err) {
		console.log(err);
		throw new Error(err.message);
	}
}

const cancel = async (wallet, item) => {
	try {
		const program = constant.program(wallet);

		const txUpdate = await program.rpc.cancelOffer(
			{
				accounts: {
					offer: new PublicKey(item.offer),
					offererAccount: new PublicKey(item.offererAccount),
					offererOfferedTokenAccount: new PublicKey(item.offererOfferedTokenAccount),
					escrowAccount: new PublicKey(item.escrowAccount),
					tokenProgram: TOKEN_PROGRAM_ID,
				}
			}
		);
		
		const action = 'cancel';
		
		return {
			txUpdate,
			action,
		};
	} catch (err) {
		console.log(err);
		throw new Error(err.message);
	}
}


const accept = async (wallet, item) => {
	const program = constant.program(wallet);
	const connection = program.provider.connection;
	
	const offeredToken = new PublicKey(item.offeredToken)
	const wantedToken = new PublicKey(item.wantedToken);

	const offereeOfferedAssociatedTokenAccount = await getOrCreateAssociatedTokenAccount(connection, wallet, offeredToken, wallet.publicKey);
	const offereeOfferedTokenAccount = offereeOfferedAssociatedTokenAccount.address;

	const offereeWantedAssociatedTokenAccount = await getOrCreateAssociatedTokenAccount(connection, wallet, wantedToken, wallet.publicKey);
	const offereeWantedTokenAccount = offereeWantedAssociatedTokenAccount.address;

	try {
		const txUpdate = await program.rpc.acceptOffer(
			{
				accounts: {
					offer: new PublicKey(item.offer),
					escrowAccount: new PublicKey(item.escrowAccount),
					offererAccount: new PublicKey(item.offererAccount),
					offereeAccount: wallet.publicKey,
					offererWantedTokenAccount: new PublicKey(item.offererWantedTokenAccount),
					offereeWantedTokenAccount: offereeWantedTokenAccount,
					offereeOfferedTokenAccount: offereeOfferedTokenAccount,
					wantedToken: new PublicKey(item.wantedToken),
					tokenProgram: TOKEN_PROGRAM_ID,
				}
			}
		);
		
		const action = 'cancel';
		
		return {
			txUpdate,
			action,
		};
	} catch (err) {
		console.log(err);
		throw new Error(err.message);
	}
};

const actions = {
	make,
	cancel,
	accept,
}

export default actions;