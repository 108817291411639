
import React, { useRef } from 'react';
import Navbar from './partials/Navbar';
import OfferForm from './partials/OfferForm';
import OffersTable from './partials/OffersTable';

const Index = () => {
    const tableRef1 = useRef();
	const tableRef2 = useRef();

    const handleCreated = () => {
        tableRef1.current.refresh();
		tableRef2.current.refresh();
    };

	return (
		<div className="d-flex flex-column vh-100">
			<Navbar />
			<div id="container" className="container-fluid overflow-auto">
				<div className="row justify-content-center my-5">
					<div className="col-xl-4 col-lg-5 col-md-7">
						<OfferForm onCreate={handleCreated} />
					</div>
				</div>
				<OffersTable ref={tableRef1} mine={true} />
				<OffersTable ref={tableRef2} mine={false} />
			</div>
		</div>
	);
}

export default Index;