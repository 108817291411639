import Loader from './Loader';

function Delete({ label, loading, className, type, onConfirm }) {
    return (
        <div className='dropdown w-100'>
            <button type='button' className={`w-100 btn btn-${type ? type : 'secondary'} ${className ? className : ''} ${loading ? 'disabled' : ''}`} data-bs-toggle='dropdown' aria-expanded='false'>
                {
                    loading
                    ?
                    <Loader className='loader-xs' />
                    :
                    <span>{label}</span>
                }
            </button>
            <div className='dropdown-menu rounded text-center w-100 p-2'>
                <p>Are you sure?</p>
                <div className='btn-group w-100' role='group' aria-label='Confirmation'>
                    <button type='button' className='btn btn-link text-success' data-bs-dismiss='modal' onClick={onConfirm}>Yes</button>
                    <button type='button' className='btn btn-link text-danger'>No</button>
                </div>
            </div>
        </div>
    );
}

export default Delete;