import React, { useEffect, useRef, useState } from 'react';
import { CancelToken, isCancel } from 'axios';
import Alert from '../plugins/Alert';
import Loader from '../plugins/Loader';
import api from '../../utils/api';
import token from '../../utils/token';
import constant from '../../utils/constant';
import filters from '../../utils/filters';

const Rates = ({ offeredToken, wantedToken, offeredAmount, onUpdate }) => {
    const mounted = useRef(false);

    const [request, setRequest] = useState(null);

    const [price, setPrice] = useState(0);
    const [rate, setRate] = useState(0);
    const [fee, setFee] = useState(0);
	const [spend, setSpend] = useState(0);
	const [receive, setReceive] = useState(0);

    const [offeredTokenSymbol, setOfferedTokenSymbol] = useState('');
    const [wantedTokenSymbol, setWantedTokenSymbol] = useState('');
    const [offeredTokenDecimals, setOfferedTokenDecimals] = useState(0);
    const [wantedTokenDecimals, setWantedTokenDecimals] = useState(0);
    const [tokenBSymbol, setTokenBSymbol] = useState('');

    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);

    const getData = () => {
        setLoading(true);
        setError('');
        const req = CancelToken.source();
        setRequest(req);
        const params = {
            cancelToken: req.token,
        };
        api.pub.benchmarks.get(params)
        .then(res => {
            if (mounted.current) {
                const data = res.data;
                setPrice(data.token);
            }
        })
        .catch(err => {
            if (mounted.current) {
                if (isCancel(err)) {
                    setError('Cancelled');
                    return;
                }
                if (err.response) {
                    const data = err.response.data;
                    setError(data.message);
                } else {
                    setError(err.message);
                }
            }
        })
        .then(() => {
            if (mounted.current) {
                setLoading(false);
            }
        });
    };

    const setData = async () => {
        setWantedTokenDecimals(await token.decimals(wantedToken));
        setOfferedTokenDecimals(await token.decimals(offeredToken));
        setOfferedTokenSymbol(await token.symbol(offeredToken));
        setWantedTokenSymbol(await token.symbol(wantedToken));
        setTokenBSymbol(await token.symbol(constant.tokenB));
    }

    const compute = () => {
        const value = +offeredAmount;
        const rate = offeredToken === constant.tokenB ? 1 / price : price;

        setRate(filters.decimal(rate, wantedTokenDecimals));

        const feeAmount = offeredToken === constant.tokenB ? value * constant.feePercent : (value * rate) * constant.feePercent;
        setFee(filters.decimal(feeAmount, offeredTokenDecimals));

        const computed = value * rate;
        const amount = filters.decimal(computed, wantedTokenDecimals); 
        onUpdate(amount);

        const spendAmount = offeredToken === constant.tokenB ? value + feeAmount : value;
        setSpend(filters.decimal(spendAmount, offeredTokenDecimals));

        const receiveAmount = offeredToken === constant.tokenA ? amount - feeAmount : amount;
        setReceive(filters.decimal(receiveAmount, wantedTokenDecimals));
    }

    useEffect(async () => {
        getData();
    }, []);

	useEffect(() => {
		compute();
        setData();
	}, [price, offeredToken, wantedToken, offeredAmount, offeredTokenDecimals, wantedTokenDecimals]);


    useEffect(() => {
        return () => {
            if (request) request.cancel();
        }
    }, [request]);

    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

	return (

		<div>
        {
            loading
            ?
            <Loader className='loader-sm' />
            :
            error
            ?
            <Alert message={error} callback={getData} />
            :
            <div>
                <p>Exchange Rate: 1 {offeredTokenSymbol} = {rate} {wantedTokenSymbol}</p>
                <p>Fee (1%): {fee} {tokenBSymbol}</p>
                <p>You will spend: {spend} {offeredTokenSymbol}</p>
                <p>You will receive: {receive} {wantedTokenSymbol}</p>
            </div>
        }
        </div>
	)
}

export default Rates;