import { TokenListProvider } from '@solana/spl-token-registry';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import constant from './constant';
import filters from './filters';

const list = async () => {
    try {
        const items = await new TokenListProvider().resolve();
        const list = items.filterByClusterSlug(constant.network).getList();
        const map = list.reduce((map, item) => {
            map.set(item.address, item);
            return map;
        }, new Map());
        return map;
    } catch(err) {
        console.log(err);
        return new Map();
    }
}

const info = async (address) => {
    return (await list()).get(address);
}

const symbol = async (address) => {
    return await info(address) ? (await info(address)).symbol : filters.shorten(address);
}

const decimals = async (address) => {
    try {
        const data = await constant.connection().getTokenSupply(new PublicKey(address));
        return data.value.decimals;
    } catch(err) {
        console.log(err);
        return 0;
    }
}

const max = async (wallet, address) => {
    try {
        const offeredTokenAccount = await getAssociatedTokenAddress(
            new PublicKey(address),
            wallet.publicKey,
        );
        
        const offeredTokenBalance = (await constant.connection().getTokenAccountBalance(offeredTokenAccount)).value;
        return offeredTokenBalance.amount / 10 ** offeredTokenBalance.decimals;
    } catch(err) {
        console.log(err);
        return 0;
    }
}

const token = {
    list,
    symbol,
    decimals,
    max,
}

export default token;