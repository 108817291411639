import React from 'react';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import Index from './components/Index';
import svg from './utils/svg';
import constant from './utils/constant';
import './App.scss';

require('@solana/wallet-adapter-react-ui/styles.css');

const App = () => {
	const wallets = [ new PhantomWalletAdapter() ];

	return (
		<ConnectionProvider endpoint={constant.cluster()}>
			<div dangerouslySetInnerHTML={{ __html: svg.defs }} style={{ height: 0 }}></div>
			<WalletProvider wallets={wallets} autoConnect>
				<WalletModalProvider>
					<Index />
				</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	)
}

export default App;