import Alert from './Alert';
import Loader from './Loader';

function DataTable({ headers, items, loading, error, sort, order, action = true, handleSort, onClick, onRetry }) {
    const TableHead = ({ name, label }) => {
        return (<th className={`sort text-nowrap ${sort === name ? order === 'asc' ? 'sort-asc' : 'sort-desc': ''}`} onClick={() => handleSort(name)}>{label}</th>);
    }

    return (
        <div className='mb-5'>
            <table className='table table-bordered table-hover'>
                <thead className='sticky'>
                    <tr>
                        {headers.map(h =>
                            h.sortable
                            ?
                            <TableHead key={h.name} name={h.name} label={h.label} />
                            :
                            <th key={h.name} className='text-nowrap'>{h.label}</th>
                        )}
                        {
                            action
                            ?
                            <th>Action</th>
                            :
                            null
                        }
                    </tr>
                </thead>
                {
                    items.length
                    ?
                    <tbody>
                        {items.map(i => 
                            <tr key={i[Object.keys(i)[0]]}>
                                {headers.map(h =>
                                    h.callback
                                    ?
                                    <td key={h.name}>{h.callback(i)}</td>
                                    :
                                    <td key={h.name}>{i[h.name]}</td>
                                )}
                                {
                                    action
                                    ?
                                    <td>
                                        <button type='button' className='btn btn-primary btn-rounded' title='Edit' onClick={() => onClick(i[Object.keys(i)[0]])}>
                                            <i className='icon icon-pen align-middle'></i>
                                        </button>
                                    </td>
                                    :
                                    null
                                }
                            </tr>
                        )}
                    </tbody>
                    :
                    !loading
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={headers.length + 1} className='text-center'>
                                No results.
                            </td>
                        </tr>
                    </tbody>
                    :
                    null
                }
            </table>
            {
                error
                ?
                <Alert message={error} callback={onRetry} />
                :
                null
            }
            {
                loading
                ?
                <Loader className='loader-sm' />
                :
                null
            }
        </div>
    );
}

export default DataTable;