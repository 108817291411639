import React, { Suspense, useEffect, useRef, useState } from 'react';
import { CancelToken, isCancel } from 'axios';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import toast from '../../utils/toast';
import constant from '../../utils/constant';
import token from '../../utils/token';
import action from '../../utils/action';
import api from '../../utils/api';
import Rates from './Rates';
import Submit from '../plugins/Submit';
import Input from '../plugins/Input';
import Loader from '../plugins/Loader';

const OfferForm = ({ onCreate }) => {
	const mounted = useRef(false);

  	const wallet = useWallet();

	const [request, setRequest] = useState(null);

	const [offeredToken, setOfferedToken] = useState(constant.tokenB);
	const [wantedToken, setWantedToken] = useState(constant.tokenA);
	const [offeredAmount, setOfferedAmount] = useState('');
	const [wantedAmount, setWantedAmount] = useState('');
	const [buying, setBuying] = useState(true);

    const [offeredTokenSymbol, setOfferedTokenSymbol] = useState('');
    const [wantedTokenSymbol, setWantedTokenSymbol] = useState('');

	const [errors, setErrors] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	
	const toastId = 'offer';

	const setData = async () => {
		setOfferedTokenSymbol(await token.symbol(offeredToken));
		setWantedTokenSymbol(await token.symbol(wantedToken));
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		let params = {};
		try {
			params = await action.offer.make(wallet, offeredToken, wantedToken, offeredAmount, wantedAmount);
		} catch (err) {
			toast.error(toastId, err.message);
			return;
		}

        const req = CancelToken.source();
        setRequest(req);
        const opts = {
            cancelToken: req.token,
        };
		api.pub.offers.create(params, opts)
		.then(res => {
			if (mounted.current) {
                const data = res.data;
                toast.success(toastId, data.message);
                onCreate(data.data);
            }
		})
		.catch(err => {
			if (mounted.current) {
                if (isCancel(err)) {
                    toast.error(toastId, 'Cancelled');
                    return;
                }
                if (err.response) {
                    const data = err.response.data;
                    if (data.hasOwnProperty('errors')) {
                        setErrors(err.response.data.errors);
                    }
                    toast.error(toastId, data.message);
                } else {
                    toast.error(toastId, err.message);
                }
            }
		})
		.then(() => {
			setSubmitting(false);
		});
	}

	const handleMaxOffered = async () => {
		setOfferedAmount(await token.max(wallet, offeredToken));
	}

	const handleUpdate = (value) => {
		setWantedAmount(value);
	}

	useEffect(() => {
		setOfferedToken(buying ? constant.tokenB : constant.tokenA);
		setWantedToken(buying ? constant.tokenA : constant.tokenB);
		setOfferedAmount('');
		setWantedAmount('');
	}, [buying]);

	useEffect(() => {
		setData();
	}, [offeredToken, wantedToken]);

    useEffect(() => {
        return () => {
            if (request) request.cancel();
        }
    }, [request]);

    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);
		
	return (
		<div>
			<form className='card card-body' onSubmit={handleSubmit}>
				<div className='mb-3'>
					<div className='d-flex w-100'>
						<Input type='number' name='offeredAmount' label={offeredTokenSymbol} value={offeredAmount} errors={errors} onInput={e => setOfferedAmount(e.target.value)} onWheel={(e) => e.currentTarget.blur()} />	
						<button className='btn btn-outline-primary ms-2' type='button' onClick={() => handleMaxOffered()}>Max</button>
					</div>
					<button 
						type='button'
						onClick={() => setBuying(!buying)}
						className='btn btn-link w-100' title='Switch'>
						&uarr;&darr;
					</button>
					<Input type='number' name='wantedAmount' label={wantedTokenSymbol} value={wantedAmount} onInput={null} readOnly={true} />	
				</div>
				<Rates offeredToken={offeredToken} wantedToken={wantedToken} offeredAmount={offeredAmount} onUpdate={handleUpdate} />
				{
					wallet.connected 
					?
					<Submit label='Submit Offer' loading={submitting} />
					:
					<Suspense fallback={<Loader className='loader-xs' />}>
						<WalletMultiButton className='justify-content-center wallet-adapter-button wallet-adapter-button-trigger' />
					</Suspense>
				}
			</form>
		</div>
	)
}

export default OfferForm;