import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { CancelToken, isCancel } from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import Delete from '../plugins/Delete';
import api from '../../utils/api';
import toast from '../../utils/toast';
import action from '../../utils/action';

const OfferActions = forwardRef(({ item, mine, onUpdate }) => {
    const mounted = useRef(false);

    const wallet = useWallet();

    const [requestAccept, setRequestAccept] = useState(null);
    const [requestCancel, setRequestCancel] = useState(null);

    const toastId = 'offers';
    
    const handleAccept = async () => {
        const req = CancelToken.source();
        setRequestAccept(req);
		let params = {};
		try {
			params = await action.offer.accept(wallet, item);
		} catch (err) {
			toast.error(toastId, err.message);
			return;
		}
        const opts = {
            cancelToken: req.token,
        };
        api.pub.offers.update(item.id, params, opts)
        .then(async (res) => {
            const data = res.data;
            onUpdate(data.data);
            toast.success(toastId, data.message);
            // getData();
        })
        .catch(err => {
            if (mounted.current) {
                if (isCancel(err)) {
                    toast.error(toastId, 'Cancelled');
                    return;
                }
                if (err.response) {
                    const data = err.response.data;
                    // if (data.hasOwnProperty('errors')) {
                    //     setErrors(err.response.data.errors);
                    // }
                    toast.error(toastId, data.message);
                } else {
                    toast.error(toastId, err.message);
                }
            }
        })
        .then(() => {
            if (mounted.current) {
                // setDeleting(false);
            }
        });
    }
    
    const handleCancel = async () => {
        const req = CancelToken.source();
        setRequestCancel(req);
		let params = {};
		try {
			params = await action.offer.cancel(wallet, item);
		} catch (err) {
			toast.error(toastId, err.message);
			return;
		}
        const opts = {
            cancelToken: req.token,
        };
        api.pub.offers.update(item.id, params, opts)
        .then(async (res) => {
            const data = res.data;
            onUpdate(data.data);
            toast.success(toastId, data.message);
            // getData();
        })
        .catch(err => {
            if (mounted.current) {
                if (isCancel(err)) {
                    toast.error(toastId, 'Cancelled');
                    return;
                }
                if (err.response) {
                    const data = err.response.data;
                    // if (data.hasOwnProperty('errors')) {
                    //     setErrors(err.response.data.errors);
                    // }
                    toast.error(toastId, data.message);
                } else {
                    toast.error(toastId, err.message);
                }
            }
        })
        .then(() => {
            if (mounted.current) {
                // setDeleting(false);
            }
        });
    }

    useEffect(() => {
        return () => {
            if (requestAccept) requestAccept.cancel();
            if (requestCancel) requestCancel.cancel();
        }
    }, [requestAccept, requestCancel]);

    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    return (
        <div className='d-flex'>
            {
                mine
                ?
                <Delete label='Cancel' className='btn-sm text-danger' type='link' onConfirm={() => handleCancel()} />
                :
                <button className='btn btn-link btn-sm w-100' onClick={() => handleAccept()}>Accept</button>
            }
        </div>
    );
});

export default OfferActions;