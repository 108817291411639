
function Alert({ message, callback }) {
    return (
        <div className='alert alert-danger d-flex align-items-center' role='alert'>
            <svg className='bi flex-shrink-0 me-3' width='24' height='24' role='img' xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 512 512' aria-label='Danger:'>
                <path d='M505.403 406.394L295.4 58.102c-8.274-13.72-23.367-22.245-39.4-22.245s-31.116 8.524-39.4 22.246L6.595 406.394a46.07 46.07 0 0 0 39.39 69.748h420.028a46.07 46.07 0 0 0 40.052-23.379c8.143-14.42 7.9-32.2-.662-46.37zm-28.364 29.978c-2.242 3.97-6.467 6.436-11.026 6.436H45.985a12.68 12.68 0 0 1-11.025-6.435c-2.242-3.97-2.172-8.862.18-12.765L245.156 75.316c2.278-3.777 6.433-6.124 10.844-6.124a12.73 12.73 0 0 1 10.843 6.124l210.013 348.292a12.68 12.68 0 0 1 .183 12.764zM256.154 173.005c-12.68 0-22.576 6.804-22.576 18.866 0 36.802 4.33 89.686 4.33 126.5.001 9.587 8.352 13.607 18.248 13.607 7.422 0 17.937-4.02 17.937-13.607 0-36.802 4.33-89.686 4.33-126.5 0-12.06-10.205-18.866-22.267-18.866zm.31 180.3c-13.607 0-23.814 10.824-23.814 23.814 0 12.68 10.206 23.814 23.814 23.814 12.68 0 23.505-11.134 23.505-23.814 0-13-10.826-23.814-23.505-23.814z' />
            </svg>
            <div><span>{message}</span> <button type='button' className='btn btn-link btn-sm alert-link ms-4' onClick={callback}>Retry?</button></div>
        </div>
    );
}

export default Alert;