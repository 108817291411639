import axios from 'axios';

const api = process.env.REACT_APP_CDN_DOMAIN || process.env.REACT_APP_API_URL + '/public';

const url = {
    benchmarks: {
        get: `${api}/benchmarks`,
    },
    offers: {
        get: `${api}/offers`,
        create: `${api}/offers`,
        read: `${api}/offers/:key`,
        update: `${api}/offers/:key`,
    },
};

const benchmarks = {
    get: (params) => {
        return axios.get(url.benchmarks.get, params);
    },
}

const offers = {
    get: (params) => { 
        return axios.get(url.offers.get, params);
    },
    create: (params, opts) => {
        return axios.post(url.offers.create, params, opts);
    },
    read: (key, params) => { 
        return axios.get(url.offers.read.replace(':key', key), params);
    },
    update: (key, params, opts) => {
        return axios.put(url.offers.update.replace(':key', key), params, opts);
    },
};

const pub = {
    benchmarks,
    offers,
};

export default pub;