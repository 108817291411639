import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';
import { Provider, Program } from '@project-serum/anchor';
import idl from '../idl/sofven_solana.json';

const opts = {
	preflightCommitment: "processed"
};

const tokenA = process.env.REACT_APP_TOKEN_ADDRESS_A;
const tokenB = process.env.REACT_APP_TOKEN_ADDRESS_B;

const feePercent = Number(process.env.REACT_APP_FEE_PERCENT);

const network = process.env.REACT_APP_NETWORK;

const programId = new PublicKey(idl.metadata.address);

const cluster = () => {
	if (network === 'localnet') return 'http://127.0.0.1:8899';
	return clusterApiUrl(network);
}

const connection = () => {
	return new Connection(cluster(), opts.preflightCommitment);
}

const provider = (wallet) => {
	return new Provider(connection(), wallet, opts.preflightCommitment);
}

const program = (wallet) => {
	return new Program(idl, programId, provider(wallet));
}

const constant = {
	tokenA,
	tokenB,
	feePercent,
    network,
	program,
    cluster,
	connection,
	provider,
};

export default constant;