const modify = (items, key, item) => {
    let list = [...items];
    let index = list.findIndex(i => i[key] === item[key]);
    list[index] = item;
    return list;
};

const remove = (items, key, id) => {
    let list = [...items];
    let index = list.findIndex(i => i[key] === id);
    list.splice(index, 1);
    return list;
};

const array = {
    modify,
    remove,
};

export default array;