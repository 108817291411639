import { Toast } from 'bootstrap';
import 'bootstrap/js/dist/toast';

const init = (key, type, message) => {
    const containerId = 'toasts';

    let container = document.getElementById(containerId);
    if (!document.body.contains(container)) {
        container = document.createElement('div');
        container.setAttribute('id', containerId);
        container.setAttribute('class', 'toast-container position-fixed bottom-0 start-0 p-3');
        container.setAttribute('style', 'z-index: 2000');
        document.body.appendChild(container);
    }

    const toastId = `toast${key}`;

    const icon = type === 'success' ? 
        `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='m369.164062 174.769531c7.8125 7.8125 7.8125 20.476563 0 28.285157l-134.171874 134.175781c-7.8125 7.808593-20.472657 7.808593-28.285157 0l-63.871093-63.875c-7.8125-7.808594-7.8125-20.472657 0-28.28125 7.808593-7.8125 20.472656-7.8125 28.28125 0l49.730468 49.730469 120.03125-120.035157c7.8125-7.808593 20.476563-7.808593 28.285156 0zm142.835938 81.230469c0 141.503906-114.515625 256-256 256-141.503906 0-256-114.515625-256-256 0-141.503906 114.515625-256 256-256 141.503906 0 256 114.515625 256 256zm-40 0c0-119.394531-96.621094-216-216-216-119.394531 0-216 96.621094-216 216 0 119.394531 96.621094 216 216 216 119.394531 0 216-96.621094 216-216zm0 0' /></svg>`
        :
        `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M505.403 406.394L295.4 58.102c-8.274-13.72-23.367-22.245-39.4-22.245s-31.116 8.524-39.4 22.246L6.595 406.394a46.07 46.07 0 0 0 39.39 69.748h420.028a46.07 46.07 0 0 0 40.052-23.379c8.143-14.42 7.9-32.2-.662-46.37zm-28.364 29.978c-2.242 3.97-6.467 6.436-11.026 6.436H45.985a12.68 12.68 0 0 1-11.025-6.435c-2.242-3.97-2.172-8.862.18-12.765L245.156 75.316c2.278-3.777 6.433-6.124 10.844-6.124a12.73 12.73 0 0 1 10.843 6.124l210.013 348.292a12.68 12.68 0 0 1 .183 12.764zM256.154 173.005c-12.68 0-22.576 6.804-22.576 18.866 0 36.802 4.33 89.686 4.33 126.5.001 9.587 8.352 13.607 18.248 13.607 7.422 0 17.937-4.02 17.937-13.607 0-36.802 4.33-89.686 4.33-126.5 0-12.06-10.205-18.866-22.267-18.866zm.31 180.3c-13.607 0-23.814 10.824-23.814 23.814 0 12.68 10.206 23.814 23.814 23.814 12.68 0 23.505-11.134 23.505-23.814 0-13-10.826-23.814-23.505-23.814z' /></svg>`
    ;
    const inner = `
        <div class='d-flex'>
            <div class='d-flex align-items-center py-2 px-3 bg-${type === 'success' ? 'success' : 'danger'}'>${icon}</div>
            <div class='toast-body'>${message}</div>
            <button type='button' class='btn-close me-2 m-auto' data-bs-dismiss='toast' aria-label='Close'></button>
        </div>
    `;
    const element = `
        <div id='${toastId}' class='toast' role='alert' aria-live='assertive' aria-atomic='true'>
            ${inner}
        </div>
    `;

    let el = document.getElementById(toastId);
    if (document.body.contains(el)) {
        document.getElementById(toastId).innerHTML = inner;
    } else {
        container.innerHTML = container.innerHTML + element;
        el = document.getElementById(toastId);
        el.addEventListener('hidden.bs.toast', () => {
            el.remove();
        });
    }
    const toast = Toast.getOrCreateInstance(el);
    toast.show();
}

const success = (key, message) => {
    init(key, 'success', message);
};

const error = (key, message) => {
    init(key, 'error', message);
};

const toast = {
    success,
    error,
};

export default toast;