import { ChatButton } from '@dialectlabs/react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import constant from '../../utils/constant';

import '@dialectlabs/react-ui/index.css';

const ChatButtonComponent = () => {
    const wallet = useWallet();
    const theme = 'dark';

    return <ChatButton
        wallet={wallet}
        network={constant.network}
        theme={theme} />
}

export default ChatButtonComponent;